import Validate from "./validate";

// 必填验证
export const inputRequired = (msg) => {
  return { required: true, message: `${msg}必填`, trigger: "blur" };
};

// 必选验证
export const selectRequired = (msg) => {
  return { required: true, message: `${msg}必选`, trigger: "change" };
};

// 手机号验证
export const phoneValid = () => {
  return {
    validator: (rule, value, callback) => {
      if (value && !Validate.isvalidPhone(value)) {
        return callback(new Error("手机号格式错误"));
      }
      callback();
    },
    trigger: "blur",
  };
};

// 小数位数验证
export const fixedNumber = (num = 2) => {
  const reg = new RegExp(`^-?\\d+\\.?\\d{0,${num}}$`);
  return {
    validator: (rule, value, callback) => {
      if (value && !reg.test(value)) {
        return callback(new Error(`最多只能有${num}位小数`));
      }
      callback();
    },
    trigger: "blur",
  };
};

export const minLength = (length = 2) => {
  return {
    validator: (rule, value, callback) => {
      if (value && value.length < length) {
        return callback(new Error(`最小长度为${length}`));
      }
      callback();
    },
    trigger: "blur",
  };
};

// 大于0
export const maxNumber = (num = 0) => {
  return {
    validator: (rule, value, callback) => {
      if (value !== "" && value <= num) {
        return callback(new Error(`需要大于${num}`));
      }
      callback();
    },
    trigger: "blur",
  };
};
