<template>
  <div class="app-body">
    <bread-crumb></bread-crumb>
    <el-card class="el-main" shadow="never">
      <el-form ref="elForm" label-position="top" :model="params" :rules="rules">
        <div class="_form-item">
          <div class="_form-header">
            <span class="_form-title">套餐信息</span>
          </div>
          <div class="_form-content">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="所属商户（简称）" prop="companyId">
                  <el-select
                    v-model="params.companyId"
                    filterable
                    clearable
                    placeholder="所属商户（简称）"
                    class="_select"
                  >
                    <el-option
                      v-for="item in agentList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="套餐名称" prop="name">
                  <el-input v-model="params.name" clearable maxlength="15" placeholder="套餐名称" />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="套餐类型" prop="type">
                  <el-select
                    v-model="params.type"
                    clearable
                    placeholder="套餐类型"
                    class="_select"
                    @change="onCouponTypeChange"
                  >
                    <el-option
                      v-for="item in couponTypeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="套餐有效期（天）" prop="expireDay">
                  <el-input
                    v-model="params.expireDay"
                    clearable
                    placeholder="套餐有效期（天）"
                    maxlength="4"
                    oninput="value=value.replace(/[^0-9]/g,'')"
                    :disabled="params.type !== '' && params.type !== 4"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="套餐金额（元）" prop="price">
                  <el-input
                    v-model="params.price"
                    clearable
                    placeholder="套餐金额（元）"
                    maxlength="4"
                    oninput="value=value.replace(/[^0-9.]/g,'')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="套餐说明">
                  <el-input
                    v-model="params.remark"
                    clearable
                    type="textarea"
                    :rows="5"
                    maxlength="500"
                    show-word-limit
                    placeholder="请输入套餐说明"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="_form-item">
          <div class="_form-header">
            <span class="_form-title">充电信息</span>
          </div>
          <div class="_form-content">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="套餐充电次数（次）" required>
                  <div class="_charge-num">
                    <div class="_charge-num-item _charge-num-type">
                      <el-form-item>
                        <el-radio-group v-model="params.chargeNumType">
                          <el-radio :label="2">有限</el-radio>
                          <el-radio :label="1">不限</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                    <div
                      v-if="params.chargeNumType === 2"
                      class="_charge-num-item _charge-num-input"
                    >
                      <el-form-item prop="chargeNum">
                        <el-input
                          v-model="params.chargeNum"
                          clearable
                          placeholder="套餐充电次数（次）"
                          maxlength="4"
                          oninput="value=value.replace(/[^0-9]/g,'')"
                        />
                      </el-form-item>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="每日充电次数（次）" prop="oneDayChargeNum">
                  <el-input
                    v-model="params.oneDayChargeNum"
                    clearable
                    placeholder="每日充电次数（次）"
                    maxlength="4"
                    oninput="value=value.replace(/[^0-9]/g,'')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="最大时长（分钟）" prop="oneTimeChargeDurationNum">
                  <el-input
                    v-model="params.oneTimeChargeDurationNum"
                    clearable
                    placeholder="最大时长（分钟）"
                    maxlength="4"
                    oninput="value=value.replace(/[^0-9]/g,'')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="免费时长（分钟）" prop="hesitationDuration">
                  <el-input
                    v-model="params.hesitationDuration"
                    clearable
                    placeholder="免费时长（分钟）"
                    maxlength="4"
                    oninput="value=value.replace(/[^0-9]/g,'')"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form>
      <!-- 按钮 -->
      <div class="_btn-wrapper">
        <el-button type="primary" @click="onSave">保存</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import axios from "axios";

import { fixedNumber, inputRequired, maxNumber, selectRequired } from "@/utils/rules";

export default {
  name: "AddCoupon",
  data() {
    return {
      params: {
        companyId: "",
        companyName: "",
        name: "",
        type: "",
        expireDay: "",
        price: "",
        remark: "",
        chargeNumType: 2,
        chargeNum: "",
        oneDayChargeNum: 2,
        oneTimeChargeDurationNum: 1440,
        hesitationDuration: 10,
      },
      rules: {
        companyId: [selectRequired("所属商户")],
        name: [inputRequired("套餐名称")],
        type: [selectRequired("套餐类型")],
        expireDay: [inputRequired("套餐有效期"), maxNumber()],
        price: [inputRequired("套餐金额"), fixedNumber(), maxNumber()],
        chargeNum: [inputRequired("套餐充电次数"), maxNumber()],
        oneDayChargeNum: [inputRequired("每日充电次数"), maxNumber()],
        oneTimeChargeDurationNum: [inputRequired("最大时长"), maxNumber()],
        hesitationDuration: [inputRequired("免费时长"), maxNumber()],
      },
      // 加盟商列表
      agentList: [],
      // 套餐卡类型
      couponTypeList: [],
    };
  },
  created() {
    axios.all([this.getAgentList(), this.getCouponTypeList()]);
  },
  methods: {
    // 获取加盟商列表
    getAgentList() {
      return new Promise((resolve) => {
        this.$Axios
          ._get({
            url: this.$Config.apiUrl.getAgentList,
          })
          .then((res) => {
            this.agentList = res.data || [];
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    // 获取套餐卡类型
    getCouponTypeList() {
      return new Promise((resolve) => {
        this.$Axios
          ._get({
            url: this.$Config.apiUrl.getCouponTypeList,
          })
          .then((res) => {
            this.couponTypeList = res.data || [];
            resolve();
          })
          .catch(() => {
            resolve();
          });
      });
    },
    // 获取套餐卡类型
    onCouponTypeChange(value) {
      const type = this.couponTypeList.find((item) => item.id === value) || {};
      this.params.expireDay = type.days || "";
    },
    // 保存
    onSave() {
      this.$refs.elForm.validate((valid) => {
        if (valid === false) {
          return;
        }

        this.$Axios
          ._post({
            url: this.$Config.apiUrl.saveCoupon,
            method: "post",
            params: {
              ...this.params,
              price: this.params.price * 100,
            },
          })
          .then((res) => {
            if (res.data === true) {
              this.$message.success("保存成功");
              this.$router.push("/coupon/coupon-list");
            } else {
              this.$message.error(res.message || "保存失败");
            }
          })
          .catch((err) => {
            this.$message.error(err.message || "保存失败");
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
._form-item {
  margin-bottom: 30px;
}

._form-header {
  margin-bottom: 16px;
  ._form-title {
    font-weight: bold;
  }
}

._select {
  width: 100%;
}

._charge-num {
  display: flex;
  align-items: center;
  /deep/ .el-radio {
    margin-right: 15px;
    &:last-child {
      margin: 0;
    }
    .el-radio__label {
      padding-left: 4px;
    }
  }
  ._charge-num-type {
    margin-right: 10px;
  }

  ._charge-num-input {
    flex: 1;
  }
}
</style>
